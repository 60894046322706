<template>
  <div class="sf-search-bar">
    <input
      class="sf-search-bar__input"
      type="search"
      :value="value"
      v-bind="$attrs"
      :placeholder="$t(placeholder)"
      v-on="listeners"
    >
    <slot name="icon">
      <SfButton
        class="sf-search-bar__button sf-button--pure"
        type="button"
        aria-label="Search"
        @click="$emit('click', value)"
      >
        <span
          v-if="icon"
          class="sf-search-bar__icon"
        >
          <SfIcon
            :color="icon.color"
            :size="icon.size"
            icon="search"
          />
        </span>
      </SfButton>
    </slot>
  </div>
</template>
<script>

import {
  SfIcon,
  SfButton,
} from '@storefront-ui/vue';

export default {
  name: 'SearchBarCustom',
  components: { SfIcon, SfButton },
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        'keydown.enter': (event) => this.$emit('keydown.enter', event.target.value),
        'keyup.esc': () => this.$emit('input', ''),
      };
    },
  },
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/molecules/SfSearchBar.scss";
</style>
