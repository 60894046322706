<template>
  <div class="sf-accordion-item">
    <slot
      name="header"
      v-bind="{
        header,
        isOpen,
        accordionClick,
        showChevron: $parent.showChevron,
      }"
    >
      <SfButton
        :aria-pressed="isOpen.toString()"
        :aria-expanded="isOpen.toString()"
        :class="{ 'is-open': isOpen }"
        class="sf-button--pure sf-accordion-item__header"
        :data-testid="'accordion-item-' + header"
        @click="accordionClick"
      >
        {{ $t(header) }}
        <slot name="additional-info" />
        <SfChevron
          tabindex="0"
          class="sf-accordion-item__chevron"
          :class="{ 'sf-chevron--right': !isOpen }"
        />
      </SfButton>
    </slot>
    <div :class="{ 'display-none': !isOpen }">
      <div class="sf-accordion-item__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

import {
  SfButton,
  SfChevron,
} from '@storefront-ui/vue';

export default {
  name: 'SfAccordionItemCustom',
  components: {
    SfChevron,
    SfButton,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    accordionClick() {
      // eslint-disable-next-line no-underscore-dangle
      this.$parent.$emit('toggle', this._uid);
    },
  },
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfAccordion.scss";
</style>
