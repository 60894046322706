<template>
  <div>
    <SearchResultsGtm
      v-if="products"
      :search-term="value"
      :has-result="products.length > 0"
    />
    <HvMegaMenu
      :visible="isSearchOpen"
      :title="$t('Search results')"
      class="search"
    >
      <SfButton
        v-e2e="'app-header-menu'"
        class="sf-button--pure sf-header__action_menu btn-close-search-result"
        data-testid="menuIcon"
        aria-label="Menu"
        @click="closeSearchResults()"
      >
        <SfImage
          src="https://cdn.lazzarionline.com/626fda6edac21c9b7d38ede9.svg"
          alt="Menu"
          class="sf-header_menu-image"
          :width="19"
          :height="19"
        />
      </SfButton>

      <transition
        name="sf-fade"
        mode="out-in"
      >
        <div
          v-if="products && products.length > 0"
          key="results"
          class="search__wrapper-results"
        >
          <HvMegaMenuColumn
            :title="$t('Search Results')"
            class="sf-mega-menu-column--pined-content-on-mobile search__categories"
          >
            <template #title="{ title }">
              <HvMenuItem :label="title">
                <template #mobile-nav-icon>
                  &#8203;
                </template>
              </HvMenuItem>
            </template>
          </HvMegaMenuColumn>
          <HvMegaMenuColumn
            :title="$t('Product suggestions')"
            class="sf-mega-menu-column--pined-content-on-mobile search__results"
          >
            <template #title="{ title }">
              <HvMenuItem
                :label="title"
                class="sf-mega-menu-column__header search__header"
              >
                <template #mobile-nav-icon>
                  &#8203;
                </template>
              </HvMenuItem>
            </template>
            <SfScrollable
              class="results--desktop desktop-only"
              show-text=""
              hide-text=""
            >
              <div class="results-listing">
                <SfProductCardCustom
                  v-for="(product, index) in products"
                  :key="index"
                  :add-to-cart-disabled="true"
                  class="result-card"
                  :regular-price="
                    $n(productGetters.getPrice(product).regular, 'currency')
                  "
                  :special-price="
                    productGetters.getPrice(product).special !== null ? $n(productGetters.getPrice(product).special, 'currency') : null
                  "
                  :max-rating="5"
                  :score-rating="productGetters.getAverageRating(product)"
                  :reviews-count="productGetters.getTotalReviews(product)"
                  :image="productGetters.getProductThumbnailImage(product)"
                  :image-alt="productGetters.getProductImageAlt(product, 'thumbnail')"
                  :title="productGetters.getName(product)"
                  :link="localePath(buildProductUrl(product))"
                  :show-wishlist-button="false"
                  @click.native="closeSearchResults()"
                />
                <!--:image-width="imageSizes.productCard.width"
                  :image-height="imageSizes.productCard.height"
                  :image="getGeminiImage(productGetters.getProductThumbnailImage(product))"-->
              </div>
            </SfScrollable>
            <div class="results--mobile smartphone-only">
              <SfProductCardCustom
                v-for="(product, index) in products"
                :key="index"
                class="result-card"
                :regular-price="
                  $n(productGetters.getPrice(product).regular, 'currency')
                "
                :max-rating="5"
                :score-rating="productGetters.getAverageRating(product)"
                :reviews-count="productGetters.getTotalReviews(product)"
                :image="productGetters.getProductThumbnailImage(product)"
                :title="productGetters.getName(product)"
                :image-alt="productGetters.getProductImageAlt(product, 'thumbnail')"
                :link="localePath(buildProductUrl(product))"
                :show-wishlist-button="false"
                @click.native="closeSearchResults()"
              />

              <!--:image-width="imageSizes.productCard.width"
              :image-height="imageSizes.productCard.height"
              :image="getGeminiImage(productGetters.getProductThumbnailImage(product))"
              :wishlist-icon="isAuthenticated ? 'heart' : ''"
              :is-in-wishlist-icon="isAuthenticated ? 'heart_fill' : ''"
              :is-in-wishlist="product.isInWishlist"
              @click:wishlist="addItemToWishlist(product)" -->
            </div>
          </HvMegaMenuColumn>
          <div class="action-buttons smartphone-only">
            <SfButton
              class="action-buttons__button color-light"
              @click="closeSearchResults"
            >
              {{ $t('Cancel') }}
            </SfButton>
          </div>
        </div>
        <div
          v-else
          key="no-results"
          class="before-results"
        >
          <p class="before-results__paragraph">
            {{ $t('You haven’t searched for items yet') }}
          </p>
          <p class="before-results__paragraph">
            {{ $t('Let’s start now – we’ll help you') }}
          </p>
          <SfButton
            class="before-results__button color-secondary smartphone-only"
            @click="closeSearchResults"
          >
            {{ $t('Go back') }}
          </SfButton>
        </div>
      </transition>
    </HvMegaMenu>
  </div>
</template>
<script>
import {
  // SfList,
  SfScrollable,
  SfButton,
  SfImage,
} from '@storefront-ui/vue';
import {
  ref, watch, computed, defineComponent,
} from '@nuxtjs/composition-api';
import { productGetters, useUser, useWishlist } from '@vue-storefront/gemini';
import { useUiHelpers, useUrlHelpers, useImage } from '~/composables';
import SfProductCardCustom from '~/components/SfProductCardCustom.vue';
import SearchResultsGtm from '~/components/Gtm/SearchResultsGtm.vue';
import HvMegaMenu from './Search/HvMegaMenu.vue';
import HvMenuItem from './Search/HvMenuItem.vue';

export default defineComponent({
  name: 'SearchResults',
  components: {
    HvMegaMenu,
    // SfList,
    SfProductCardCustom,
    SfScrollable,
    HvMenuItem,
    SfButton,
    SfImage,
    SearchResultsGtm,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props, { emit }) {
    const { isAuthenticated } = useUser();
    const { buildProductUrl } = useUrlHelpers();
    const { isInWishlist, addItem, removeItem } = useWishlist('GlobalWishlist');

    const th = useUiHelpers();
    const isSearchOpen = ref(props.visible);
    const products = computed(() => {
      const res = props.result;
      return res?.products;
    });

    const categories = computed(() => props.result?.categories);

    watch(
      () => props.visible,
      (newVal) => {
        isSearchOpen.value = newVal;
        if (isSearchOpen.value) {
          document.body.classList.add('no-scroll');
        } else {
          document.body.classList.remove('no-scroll');
          emit('removeSearchResults');
        }
      },
    );

    const closeSearchResults = () => {
      emit('removeSearchResults');
    };

    const addItemToWishlist = async (product) => {
      await (
        isInWishlist({ product })
          ? removeItem({ product })
          : addItem({ product })
      );
    };

    const { getGeminiImage, imageSizes } = useImage();

    return {
      th,
      isSearchOpen,
      productGetters,
      products,
      categories,
      isAuthenticated,
      closeSearchResults,
      addItemToWishlist,
      buildProductUrl,
      getGeminiImage,
      imageSizes,
    };
  },
});
</script>
<style lang="scss" scoped>
.btn-close-search-result {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  @include for-mobile {
    top: 24px;
    right: 16px;
  }
}

.search {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 3;
  --mega-menu-column-header-margin: var(--spacer-sm) 0 var(--spacer-xl);
  --mega-menu-content-padding: 0;
  --mega-menu-height: auto;
  @include for-desktop {
    --mega-menu-content-padding: var(--spacer-xl) 0;
  }
  &__wrapper-results {
    display: flex;
    flex-direction: column;
    @include for-desktop {
      flex-direction: row;
      flex: 1;
    }
  }
  &__categories {
    flex: 0 0 220px;
  }
  &__results {
    flex: 1;
  }
  &__header {
    margin-left: var(--spacer-sm);
  }
  ::v-deep .sf-bar {
    display: none;
  }
  ::v-deep .sf-mega-menu-column__header {
    display: none;
    @include for-desktop {
      display: flex;
    }
  }
}
.results {
  &--mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background: var(--c-white);
    padding: var(--spacer-base) var(--spacer-sm);
    --product-card-max-width: 9rem;
  }
}
.see-all {
  padding: var(--spacer-xl) 0 0 var(--spacer-sm);
}
.action-buttons {
  padding: var(--spacer-xl) var(--spacer-sm) var(--spacer-3xl);
  background: var(--c-white);
  width: 100%;
  &__button {
    width: calc(100% - 32px);
  }
}
.results-listing {
  display: flex;
  flex-wrap: wrap;
  margin-left: var(--spacer-2xs);
}
.result-card {
  margin: var(--spacer-sm) 0;
  @include for-desktop {
    margin: var(--spacer-2xs) 0;
  }
}

.before-results {
  box-sizing: border-box;
  padding: var(--spacer-base) var(--spacer-sm) var(--spacer-2xl);
  width: 100%;
  text-align: center;
  @include for-desktop {
    padding: 0;
  }
  &__picture {
    --image-width: 230px;
    margin-top: var(--spacer-2xl);
    @include for-desktop {
      --image-width: 18.75rem;
      margin-top: var(--spacer-base);
    }
  }
  &__paragraph {
    font-family: var(--font-family--primary);
    font-weight: var(--font-weight--normal);
    font-size: var(--font-size--base);
    color: var(--c-text-muted);
    margin: 0;
    @include for-desktop {
      font-size: var(--h1-font-size);
    }
    &:first-of-type {
      margin: var(--spacer-xl) auto var(--spacer-xs);
    }
  }
  &__button {
    margin: var(--spacer-xl) auto;
    width: 100%;
  }
}

.search__wrapper-results {
  width: 100%;
}
</style>
<style lang="scss">
.sf-mega-menu {
  @include for-mobile {
    height: 100vh !important;
    overflow-y: scroll;
  }

  &.search {
    @include for-desktop {
      height: 70vh;
      overflow-y: scroll;
      .sf-scrollable__content {
        max-height: unset;
      }
    }
  }

  .sf-mega-menu__menu {
    position: relative;
    @include for-mobile {
      padding: 0 16px;
    }
  }

  .hv-title {
    @include for-mobile {
      padding: 0;
      padding-top: 24px;
    }
  }

  .search__categories .hv-title span.sf-menu-item__label {
    @include for-mobile {
    margin: 0;
    }
  }

  .action-buttons__button {
    width: 100%;
  }

  .search__categories {
    @include for-desktop {
      display: none;
    }
    @include for-mobile {
      flex: auto;
      .sf-mega-menu-column__content {
        display: none;
      }
    }
  }

  .search__results {
    .result-card {
      display: flex;
      flex-direction: column;

      .sf-product-card__image-wrapper{
        flex-grow: 1;
      }

      @include for-desktop {
        max-width: calc(100% / 5);
        width: 100%;
      }

      .sf-product-card__image-wrapper {
        button.sf-product-card__add-button {
          display: none;
        }
      }

      span.sf-product-card__title {
        color: #0b0e0c;
        font: 400 16px "Frank Ruhl Libre";
        line-height: 24px;
      }

      span.sf-price__regular {
        font-family: "Spartan";
        font-size: 12px;
      }

      @include for-mobile {
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }

    @include for-mobile {
      .results--mobile {
        padding: 24px 0;
        justify-content: space-between;
        column-gap: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));;
        overflow: hidden;
        width: 100%;
        max-width: 100%;

        .sf-product-card {
          max-width: inherit;
          .sf-product-card__image-wrapper {
            max-width: inherit;
            .sf-product-card__link{
              max-width: inherit;
              .sf-product-card__image{
                max-width: inherit;
                img {
                  max-width: inherit;
                }
              }
            }
          }
        }

        div.sf-product-card:nth-child(odd) {
          grid-column-start: 1;
        }

        div.sf-product-card:nth-child(even) {
          grid-column-start: 2;
        }

      }
    }
  }
}
</style>
